function setCookie(name, value, hours) {
  let expires = '';
  if (hours) {
    const date = new Date();
    date.setTime(date.getTime() + (hours * 60 * 60 * 1000));
    expires = '; expires=' + date.toUTCString();
  }
  document.cookie = name + '=' + (value || '') + expires + '; path=/';
}

function getCookie(name) {
  const nameEQ = name + '=';
  const ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}

$('#acceptCookies').on('click', function(event) {
  event.preventDefault();
  setCookie('acceptCookies', 'true', 96);
  hideBanner();
});


function acceptCookies() {
  setCookie('acceptCookies', 'true', 96);
  hideBanner();
}

function hideBanner() {
  document.getElementById('cookieBanner').style.display = 'none';
}

window.onload = function() {
  if (getCookie('acceptCookies')) {
    hideBanner();
  }
};
